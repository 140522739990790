import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import ReactGA from "react-ga";
import butter from "./utils/butterClient";

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Layout from "./containers/layout";
import Index from "./containers/index";
import Product from "./containers/product";
import Cupid from "./containers/cupid";
import Services from "./containers/services";
import Team from "./containers/team";
import Vacancies from "./containers/vacancies";
import Job from "./containers/job";
import Contact from "./containers/contact";
import Legal from "./containers/legal";
import SurveyWrapper from "./containers/surveyWrapper";
import GameProfile from "./containers/gameprofile";
import ReelHot from "./containers/reelHot";
import ReelScan from "./containers/reelscan";
import ReelCast from "./components/ReelCast/reelcast";
import S01E01 from "./components/ReelCast/Episodes/s01e01-inventory-overdiversification-part-i";
import S01E02 from "./components/ReelCast/Episodes/s01e02-inventory-overdiversification-part-ii";
import S01E03 from "./components/ReelCast/Episodes/s01e03-making-your-peace-with-premium";
import S01E04 from "./components/ReelCast/Episodes/s01e04-top-ten-insights-of-2022";
import S02E01 from "./components/ReelCast/Episodes/s02e01-updating-best-practices-part-i";
import S02E02 from "./components/ReelCast/Episodes/s02e02-updating-best-practices-part-ii";
import S02E03 from "./components/ReelCast/Episodes/s02e03-updating-best-practices-part-iii";
import S02E04 from "./components/ReelCast/Episodes/s02e04-jackpot-liabilities-and-high-denom-links";
import S02E05 from "./components/ReelCast/Episodes/s02e05-marketing-mix-management-with-lucien-wijsman";
import S02E06 from "./components/ReelCast/Episodes/s02e06-video-poker-with-action-gamings-mike-fields";
import S02E07 from "./components/ReelCast/Episodes/s02e07-operator-vendor-tensions-with-buddy-frank";
import S02E08 from "./components/ReelCast/Episodes/s02e08-increased-jackpot-activity-g2e-2023";
import S02E09 from "./components/ReelCast/Episodes/s02e09-budgetary-guidance-for-2024";
import S02E10 from "./components/ReelCast/Episodes/s02e10-aruze-gaming-global-with-kelcey-allison";
import S02E11 from "./components/ReelCast/Episodes/s02e11-top-ten-insights-of-2023";
import S03E01 from "./components/ReelCast/Episodes/s03e01-bc-slots-with-brian-christopher-josh-o-connell";
import S03E02 from "./components/ReelCast/Episodes/s03e02-delaware-north-with-michael-carruthers";
import S03E03 from "./components/ReelCast/Episodes/s03e03-blue-delta-jeans-with-nick-weaver";
import S03E04 from "./components/ReelCast/Episodes/s03e04-bally-s-corporation-with-dan-cherry";
import S03E05 from "./components/ReelCast/Episodes/s03e05-gaming-arts-with-mike-dreitzer";
import S03E06 from "./components/ReelCast/Episodes/s03e06-ags-with-jackson-floyd-and-steve-walther";
import S03E07 from "./components/ReelCast/Episodes/s03e07-incredible-technologies-with-dan-schrementi";
import S03E08 from "./components/ReelCast/Episodes/s03e08-zitro-usa-with-derik-mooberry";
import S03E09 from "./components/ReelCast/Episodes/s03e09-bluberi-with-mike-brennan-casey-whalen";
import S03e10 from "./components/ReelCast/Episodes/s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau";
import S03E11 from "./components/ReelCast/Episodes/s03e11-thanksgiving-episode";

import * as serviceWorker from "./serviceWorker";
import "./scss/main.scss";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cms_content: {} };

        ReactGA.initialize("UA-61018964-3");
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        butter.content.retrieve(["texts,images"]).then(response => {
            const cms_data = {};
            response.data.data.texts.forEach(node => {
                cms_data[node.content_key] = {
                    title: node.title,
                    text: node.text
                };
            });
            response.data.data.images.forEach(node => {
                cms_data[`image.${node.content_key}`] = { url: node.image };
            });
            this.setState({ cms_content: cms_data });
        });
    }

    render() {
        return (
            <>
                <Router>
                    <Layout cms_content={this.state.cms_content}>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => (
                                    <Index
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/slot-machine-data-analytics-platform"
                                render={() => (
                                    <Product
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/services"
                                render={() => (
                                    <Services
                                        cmsContent={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/reelhot"
                                render={() => (
                                    <ReelHot
                                        cmsContent={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/cupid"
                                render={() => (
                                    <Cupid
                                        cmsContent={this.state.cms_content}
                                    />
                                )}
                            /> 
                            
                            <Route
                                path="/reelscan"
                                render={() => (
                                    <ReelScan
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/reelcast"
                                render={() => (
                                    <ReelCast
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/about-us"
                                render={() => (
                                    <Team
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/test-route"
                                render={() => (
                                    <Contact
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />{" "}
                            {/* For /platform need to set up */}
                            <Route
                                path="/platform"
                                render={() => (
                                    <Product
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/contact"
                                render={() => (
                                    <Contact
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/careers"
                                render={() => (
                                    <Vacancies
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/job/:title"
                                render={props => (
                                    <Job
                                        {...props}
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/legal"
                                render={() => (
                                    <Legal
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/venue-survey/:code"
                                render={props => <SurveyWrapper {...props} />}
                            />
                            <Route
                                path="/games/:uuid/:title"
                                render={props => <GameProfile {...props} />}
                            />
                            <Route
                                path="/s01e01-inventory-overdiversification-part-i"
                                render={() => (
                                    <S01E01
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s01e02-inventory-overdiversification-part-ii"
                                render={() => (
                                    <S01E02
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s01e03-making-your-peace-with-premium"
                                render={() => (
                                    <S01E03
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s01e04-top-ten-insights-of-2022"
                                render={() => (
                                    <S01E04
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e01-updating-best-practices-part-i"
                                render={() => (
                                    <S02E01
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e02-updating-best-practices-part-ii"
                                render={() => (
                                    <S02E02
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e03-updating-best-practices-part-iii"
                                render={() => (
                                    <S02E03
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e04-Jackpot-Liabilities-and-High-Denom-Links"
                                render={() => (
                                    <S02E04
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e05-marketing-mix-management-with-lucien-wijsman"
                                render={() => (
                                    <S02E05
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e06-video-poker-with-action-gamings-mike-fields"
                                render={() => (
                                    <S02E06
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route 
                                path="/s02e07-operator-vendor-tensions-with-buddy-frank"
                                render={() => (
                                    <S02E07
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e08-increased-jackpot-activity-g2e-2023"
                                render={() => (
                                    <S02E08
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e09-budgetary-guidance-for-2024"
                                render={() => (
                                    <S02E09
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s02e10-aruze-gaming-global-with-kelcey-allison"
                                render={() => (
                                    <S02E10/>
                                )}
                            />
                            <Route
                                path="/s02e11-top-ten-insights-of-2023"
                                render={() => (
                                    <S02E11
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            /> 
                            <Route
                                path="/s03e01-bc-slots-with-brian-christopher-josh-o-connell"
                                render={() => (
                                    <S03E01
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e02-delaware-north-with-michael-carruthers"
                                render={() => (
                                    <S03E02
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e03-blue-delta-jeans-with-nick-weaver"
                                render={() => (
                                    <S03E03
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e04-bally-s-corporation-with-dan-cherry"
                                render={() => (
                                    <S03E04
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e05-gaming-arts-with-mike-dreitzer"
                                render={() => (
                                    <S03E05
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e06-ags-with-jackson-floyd-and-steve-walther"
                                render={() => (
                                    <S03E06/>
                                )}
                            />
                            <Route
                                path="/s03e07-incredible-technologies-with-dan-schrementi"
                                render={() => (
                                    <S03E07
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e08-zitro-usa-with-derik-mooberry"
                                render={() => (
                                    <S03E08
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e09-bluberi-with-mike-brennan-casey-whalen"
                                render={() => (
                                    <S03E09
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route 
                                path="/s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau"
                                render={() => (
                                    <S03e10
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            />
                            <Route
                                path="/s03e11-thanksgiving-episode"
                                render={() => (
                                    <S03E11
                                        cms_content={this.state.cms_content}
                                    />
                                )}
                            /> {/* Buffon */} 
                            <Route component={() => <Redirect to="/" />} />
                        </Switch>
                    </Layout>
                </Router>
            </>
        );
    }
}

function main() {
    ReactDOM.render(<App />, document.getElementById("root"));
}

function loadPolyfills() {
    // only brings the polyfills we need, replaces suspicious polyfill.io service
    const polyfills = [];

    if (!('fetch' in window)) {
        polyfills.push(import('whatwg-fetch'));
    }

    if (!('values' in Object)) {
        polyfills.push(import('core-js/features/object/values'));
    }

    Promise.all(polyfills).then(() => {
        main();
    }).catch(err => {
        // eslint-disable-next-line no-console
        console.error('Error loading polyfills', err);
        main();
    });
}

loadPolyfills();

serviceWorker.unregister();
