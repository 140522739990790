import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E11 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname); 
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>{`${this.props.title} | ${this.props.subTitle} | ReelCast by ReelMetrics`}</title>

                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s03e11-thanksgiving-episode"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s03e11-thanksgiving-episode"
                    />
                    <meta property="og:title" content="ReelCast - S03E11" />
                    <meta
                        property="og:description"
                        content={this.props.description}
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/MQQRO9sOS12of786PyT6" 
                    />
                    <meta property="og:locale" content="en_US" />
                
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

    S03E11.defaultProps = {
        id: "16172077-s03e11-thanksgiving-episode",
        link: "https://www.reelmetrics.com/s03e11-thanksgiving-episode",
        title: "Thanksgiving Episode",
        subTitle: "Season 3, Episode 11",
        description: "In this Thanksgiving-themed episode, Nick and Don list the top ten current slot trends for which they’re most thankful. Also in this episode, game speed and the Las Vegas Grand Prix (mea culpa edition).",
        topics: [ 
            "Comparing game speed across manufacturers",
            "Slot designs targeting host-level players",
            "Expanding product successes",
            "Influencer impacts on player behavior",
            "Peak occupancy analysis & saturation levels",
            "Maximizing multi-denom",
        ],    
        keywords: [
            "slot machine trends, player preferences, casino floor analytics, gaming floor optimization, revenue growth strategies, casino performance metrics, casino marketing analytics, player behavior insights, high-value customer engagement, influencer impact on casinos, social gaming trends, multi-denomination slot machines, casino technology trends, average bet growth, casino analytics solutions, data-driven decision-making in casinos, CapEx planning, slot machine ROI, casino revenue concentration, high-performing slot themes"
        ],    
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/16172077-s03e11-thanksgiving-episode",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e11-thanksgiving-episode",
    
    transcript: `Nick Hogan:
Good morning, Don. How are things in the St. Louis area today?
Don Retzlaff:
Another beautiful day. How about Leiden?
Nick Hogan:
Very cold, very gray, and lots of rain. So we are fully in winter at this point. So let's see. Don, Las Vegas's second F-1 Grand Prix is finally in the tank with Englishman George Russell grabbing first place. Just seven seconds ahead of fellow Englishman Lewis Hamilton. So a good night for the Brits apparently.
Don Retzlaff:
It was a nice race.
Nick Hogan:
Did you watch it?
Don Retzlaff:
I did. Yeah, it came on about, I made 11PM local time and, but it was a really nice race. I really enjoyed it.
Nick Hogan:
What happened? Anything happened with Verstappen? Because he's a big thing over here of course.
Don Retzlaff:
I think he finished fourth and that clinched a championship for him, if I remember right.
Nick Hogan:
Oh, really? Oh, okay. Well, cool, cool, cool. Okay. Well far more interesting for me than the race, I'm not much of an auto racing guy, but it's everything surrounding it. So a few facts and figures that I dug up. So the average three-night room rate for the properties pulled in Vegas was $1,372 this year, down 41% from last year's mean of $2,314. Airbnb rates were down 73% at $533 for a three-night stay versus last year's $1,946. And ticket prices too were down 76% with general admission tickets in the South Koval Zone, which is more toward locals starting at 99 bucks. So if you were looking to place a price tag on the event's first year hype, I guess you have a few data points there. It should make it a little easier, but equally interesting for me, Don, is the community outreach that Las Vegas Grand Prix did this year to extend an olive branch of sorts to the locals.
If you recall, last year's disruptions were not only hugely inconvenient for locals, but materially damaging to a lot of local businesses and really kind of badly exacerbated by the kind of tone-deaf conduct of the race organizers. So if you recall, it was they were charging businesses located on the track, insane royalty fees and placing visual obstructions in front of businesses that refused to play ball. And even going so far as to put foil on the glass on the strip's pedestrian bridges so that nobody could get a free look.
So wasn't exactly making a lot of friends of Southern Nevadans there, but a few things they did this year. So apparently they really increased the coordination with municipal authorities and strip properties about traffic disruptions of street closures, that stuff. They created a locals-oriented Las Vegas Grand Prix fan experience, which was a free kind of locals-oriented event where people could check out F-1 stuff from local vendors and meet some of the teams, etc. And then they created these two locals-oriented spectator zones. One was at Caesars as I understand it, and the second one at that South Koval location that I mentioned. So it's a bit early to hear how all this went down with Las Vegans, but I expect plenty of journalists are going to be writing this up over the coming weeks. So have you spoken with any locals about this year's event, Don?
Don Retzlaff:
A little bit. It was more just like you just talked about, the growing pains from year one and the things that they were doing differently. I still have several friends that decide that's the good weekend to get out of town,-
Nick Hogan:
Sure.
Don Retzlaff:
And to avoid all that, especially the people who live anywhere near the strip. I've got a friend who lives out by the Orleans and yeah, it's a good weekend to get away. They go to Phoenix or they go up to Reno, Tahoe area. But I heard many of the same things. The fan experiences are great. I highly recommend that. If you ever get a chance to do one of the F-1 or Super Bowls, World Series, All-Star games, stuff like that, those are really neat experiences for fans and kids and so I'm glad to hear they're doing better. Obviously had a lot of growing pains. I think it was, they didn't reach those lofty expectations in year one, but I think they found a base that they can build off of.
Nick Hogan:
Yeah. And I see this as really positive because I mean, Las Vegas is anything, it is one very resilient city and I have no doubt that they'll improve this iteratively and this will just get better and better. So anyway. Okay, so listener questions, Don. Before I read it, let me just say that we love to tackle any questions that anybody listening may have. If you have a question about what we're presenting or something you like us to present, please drop us an email at reelcastreelmetrics.com. Again, that's R-E-E-L-C-A-S-T at reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That's not something we do.
Okay. Don. So this one comes from an operator in Southern Europe and reads, "Dear gentlemen, we at our casino listen to your show every month, and we have very positive results. Looking more closely at player demand and buying more of the big aristocrat and L&W games that you often discussed. This has worked very well. In playing them myself, I have the feeling that these games play much faster than other games. Do you track this? If so, are these games actually faster? Thank you very much. We find your show very useful here." So Don, I know you track this, so I'll just turn it over to you.
Don Retzlaff:
Well, first thank you for the kind words. It's very thoughtful. I appreciate that. But yeah, it is something we track. Since we started that at Cupid initiative a few years back, we've really looked at a lot of player data and one of the things we track is spins per minute, both on a game level and on a vendor level and our listener's. Correct.
Nick Hogan:
And a segment level, we're tracking this on a segment level too, are we not?
Don Retzlaff:
Yeah, we are.
Nick Hogan:
How fast different groups of players play it. Yeah.
Don Retzlaff:
And I guess I can start there. Your host level players actually play the games faster than your low theoretical players, and it's not even close. It's usually by a spin or more per minute faster. They just don't let those credits rack up. They want to get right back into the action. So yeah, we'll see a difference of a spin per minute or more from your host level players down to your low theoretical. But some manufacturers do produce games that play faster and Aristocrat and Light & Wonder are two of them. IGT's games play fast, but the typical Aristocrat game plays between 9% and 23% faster than the other manufacturers.
Nick Hogan:
Oh, wow. I wasn't aware of that.
Don Retzlaff:
Yeah. I looked at the top 10 manufacturers, I just did it this morning and Aristocrat games for host level customers average 12 and a half spins per minute on the top 10 games.
Nick Hogan:
Man.
Don Retzlaff:
And that's way above what it was pre-COVID. I remember back doing a study with Penn. We were looking at eight and a half to nine spins per minute. This goes back probably five, six years ago, but now almost every manufacturer I looked at today was over 10 spins per minute for their top 10 themes.
Nick Hogan:
Wow. Okay.
Don Retzlaff:
Yeah.
Nick Hogan:
So indeed in this case with this operator, he perceived it. And that's pretty interesting to me, that man, that's a lot faster though. Those numbers really kind of shock me.
Don Retzlaff:
Yeah. Can you imagine just having organic growth of nine to 23% just by sitting them on the right machine and not spending,-
Nick Hogan:
Yeah.
Don Retzlaff:
That's a marketer's dream.
Nick Hogan:
For sure. Okay. Yeah. Well, cool. And huge thanks to the listener for that question. It was a good one, type of stuff we really, really like to hit, so. Okay, so now Don, last month we finished a run of interviews with seven different slot vendors. I counted them down today. So we did Aruze Gaming Arts, AGS, Incredible Technologies, Zitro, Bluberi and Konami. And before jumping into others, we decided to take a bit of a quick breather on the guest episode. So considering the whole world just endured a US election packed full of bloviating, backbiting, sniping, and overall negativity, we thought it'd be nice to shift gears a bit and do an episode packed full of positivity. So as it's that time of year, we thought we'd do a Thanksgiving episode and discuss the upbeat slot industry trends that have both of us excited and thankful basically.
So I think we can just toggle here. Each of us came up with five, and I think we'll just swap out here after each one. So I'll kick it off. So the first trend for which I'm thankful is that a number of suppliers and truly most notably led by those we just mentioned, Aristocrat, Light & Wonder, they really seem to have dialed in to the preferences of these host level players, creating these faster, more expensive and much wilder games. Right. So this trend covers both core and premium offerings, and as we said earlier about the segment specificity, the resonance that we see within that A segment, so your best players overall is just nothing short of astonishing.
So we see A segment index values, session links, and average bets that are often two to three times what we see in the BNC segments. And that multiple, or excuse me, that multi-trip player persistence is simply off the charts. These players are returning to the games again and again and again. So to me, Don, this is crazily encouraging and a trend I hope spreads to incorporate a longer list of players. But any thoughts on that one, Don, about this trend?
Don Retzlaff:
Yeah, I agree. It's been really positive the last couple of years. You're seeing some really good games come out and it's all catered towards the host level customers. One of the things that we keep telling our clients is when you're thinking about spending your CapEx dollars in 2025, think about your host level players because the games that are doing the best are all tailored towards them, and your lower theoretical, high-frequency players aren't playing them.
Nick Hogan:
Yeah.
Don Retzlaff:
So you're looking at all the best nickels and dime video games that are coming out are coming from ATI and Light & Wonder. Mr. the Lion from IGT is doing really well too, and Konami has All Aboard, which apparently you're going to be able to buy here shortly. Those are all doing well, but there are so many great penny themes that are popular with the host level players and it just keeps getting better and better every year.
Nick Hogan:
Now as a player, do you feel more appreciated in this respect? So you find yourself looking at more options of games that you really love?
Don Retzlaff:
Yeah, absolutely. And you can see it in the wallet spend, both my personal wallet spend and looking at what our host level players are playing. It's gravitating towards the stuff that's only been out in the last 18 months to 24 months. Every year that percentage for host level players is just getting spent more and more on either lease games or the very best in the core games.
Nick Hogan:
Yeah, yeah. Okay.
Don Retzlaff:
They really arching right away from the classics. The classics are being upheld by those under $200 theoreticals. The best players are playing the new stuff and they're playing it hard.
Nick Hogan:
Yeah, yeah. Okay, cool. So your first Don.
Don Retzlaff:
Well kind of dovetailing onto that is casinos placing great themes in higher numbers on the floor. And honestly, I think this is the number one thing you can do to your floor to make it run better. Give your host of players the themes they like to play on in volume. I just can't overstate this too much. If you do nothing else, do this. Don't just put one or two of your best themes on the floor, make it a whole bank.
We're seeing great results from our clients that are placing an entire bank of themes like Huff N' Even More Puff on the floor instead of the classic way, the way I was brought up to do it, if it was a bank of six, you'd have three different themes, but now we have clients that are placing 10, 15, 20, Huff N' Even More Puffs on the floor and they're seeing great results. We have one client that has 22 of them on the floor and they're doing 2.7 times house average.
Nick Hogan:
Nice.
Don Retzlaff:
Yeah, don't worry about over saturating your floor. You're not going to reach that level. And the average bets we're seeing on these themes from host level players on Huff N' Even More Puff is in the top five overall. So you're looking at nearly $5 a spin on this game from your host level customers. So limit your themes and place the great ones on the floor in high numbers.
Nick Hogan:
Okay, excellent. Okay, so my next one, next trend for which I'm thankful is that influencers I believe are encouraging more aggressive and psychologically at least arguably more rewarding slot play. So we're seeing bigger bank rolls, bigger average bets, longer sessions, smarter play, covering all lines, et cetera. And I'd assume more trips overall, although I haven't looked into that one directly, but I did look today, Don and I see now that Brian Christopher, who we interviewed, I guess it was January of this year, they're now up to one and a half million subscribers across YouTube and Facebook, so.
Don Retzlaff:
That's incredible.
Nick Hogan:
Yeah. And as you and I have discussed many times, we can see them influencing player behavior and I think the interesting thing is their captive audience seems to be players, so.
Don Retzlaff:
Absolutely.
Nick Hogan:
Any thoughts from your side on this one, Don, on the influencer effect?
Don Retzlaff:
Yeah, I think we mentioned it during that interview with Brian was I'm seeing it both on land-based casinos and in the cruise industry. I've got a cruise booked for early 2025 and I'm already seeing Facebook posts for people that are signing people up for large group polls.
Nick Hogan:
Yeah.
Don Retzlaff:
And those things didn't happen before, and I'm seeing it where people are pooling their money at the casinos, they're pooling their money on the cruise lines and they're playing large average bets. They're playing 25, $50 average bets and they're trying to chase that jackpot or that one large bonus rounds. So it's happening more and more and this is something that's probably, it's post COVID.
Nick Hogan:
Yeah.
Don Retzlaff:
But it's really, really taken off the last two years.
Nick Hogan:
Yeah. Agreed. Okay, your next one, Don.
Don Retzlaff:
Thankful that casinos are looking at weekend occupancy for expansion opportunities. This has been something we've really stressed. Too many of the best games have peak period occupancy well above what we'd like to see, and it's causing a loss of revenue for the casinos. Your best players will play longer and they'll play at a higher average bet when they play the games that are higher on our list of themes they want to play. It's been one of our core messages the last couple of years. Most of your best guests have an idea of what they want to play when they drive at the casino. I know my girlfriend and I talk about it when we're driving over the casino, what do you want to play tonight? So you have that core list of things that you want to play, so you will not get deep into their wallet if they have to play games that they enjoy less than their favorites.
We've done studies that have shown a 20% decline in trip wallet if they can't play in the games that they want to play. And it usually happens on Thursday night, Friday nights, Saturdays and Sundays when you have a lot of players in the casino and the customers just can't get on their favorites. Think of a game like a Dragon Cash game where you're seeing average bets north of $10 a spend from host level customers.
But what happens if you only have one of them and it's busy? Well, that customer is either going to stand in line and wait, which is awful, or they're going to play another title and their average bet's going to drop to four or $5 a spend. Still a really strong average bet, but you're going to get half of the average bet that you would've if they had played the game that they wanted to play. So if you have themes that are running north of 50% occupancy on the weekends, you might want to look at expansions. I usually use about 75% for lease games and anything north of 40% for a core game or 50% for a core game on the weekend, you're probably losing some revenue.
Nick Hogan:
Yeah, yeah. Okay. Don, you mentioned a 20% decay in productivity. I know that we've seen decays much larger than that. We've seen them three times, 60% of 40s, and it is a real effect. I mean, we can just track it very directly.
Don Retzlaff:
I think the first experiment that we did, we showed a 60% decline on peak period occupancy versus non-peak occupancy when they couldn't play the games they wanted. So that's a large number.
Nick Hogan:
Yeah. And so just to underscore that, what it means really is that people are coming into the venues with a budget and if they can get on what they want to play, you're going to get the budget. And if they're playing what they have to play, you'd be lucky to get 30, 40% of it or 40, 50% of it, and it's just really comes down to choice. That's it.
Don Retzlaff:
Yep.
Nick Hogan:
Okay, cool. Next up for me, somewhat related to my second point. In-venue sports wagering and influencers are introducing slots to a new generation of slot players. So Don, you've done a fair bit of work on this one, so I'll want some input from you here, but perhaps we could just start with some of those assumptions that folks had in building up the big ETG inventories in conjunction with the sports openings and stuff. Maybe talk about that a little bit.
Don Retzlaff:
Sure. Yeah, I was really surprised because I was along with all the chief marketing officers and all the people in table games and slots, basically saying under 45 crowd was going to be in for the social aspect of the games, so they were going to play table games and ETGs, but when we looked at the numbers, it wasn't happening.
Nick Hogan:
Yeah.
Don Retzlaff:
Females under the age of 45, it was 80% of their wallet was spent on video slots.
Nick Hogan:
Yep.
Don Retzlaff:
And even for the men under 45, 60% of their wallet was spent on video. The combination of ETGs and video poker was about 7% of their wallet.
Nick Hogan:
Yep.
Don Retzlaff:
There is some nice table games play under the age of 45, but the vast majority of the wallet is spent on video and it's mostly video penny units and the neat thing about that was what I found was their favorite games are lease games. They play a higher percentage of their wallet on lease games than any other age bracket. It was 40% of their wallet is spent on lease games.
Nick Hogan:
Okay. So 40% of that under 45 wallet is going into high octane lease. And they're chasing jackpots, I assume.
Don Retzlaff:
They are. Their average bets are a lot higher than you would expect for a wallet that size. Their average bets are probably too large for the wallet that they have, so their risk of ruin an old table games term is pretty high. So their session times can be shorter, but they are chasing jackpots, they're chasing the big bonus rounds. They'll bet up, and they're playing games like actually the number one game for an under 45 man was surprising. It's actually Dragon Cash, not Dragon Link, but Dragon Cash. And I did not expect that.
Nick Hogan:
Yeah.
Don Retzlaff:
I expected Dragon Cash to be the favorite for your high theoretical customers, but this went across all ADT bands for under 45 men, whether they were bringing in 100, 200 or $500 to Casino Dragon Cash was the game they liked to play the most.
Nick Hogan:
I'll be damned.
Don Retzlaff:
It really, you know what? I can see why as a better, it's basically that parlay mentality.
Nick Hogan:
Yeah.
Don Retzlaff:
I'm going to take a shot and hit a jackpot and I don't want to play for three hours, I want to play for 15 minutes and I want to take my shot. And if I get a nice bonus round betting $10 a spin, I'm done.
Nick Hogan:
Yeah.
Don Retzlaff:
But,-
Nick Hogan:
One thing I hadn't asked you Don was what was it like with their frequency? What kind of visitation are we seeing on this segment?
Don Retzlaff:
There's a little bit of a split. The difference between a 21 to a 34-year-old is a lot lower than that 35 to 45. That 35 to 45 age bracket is starting to play a lot more.
Nick Hogan:
Okay.
Don Retzlaff:
I guess they've had a little more disposable income and their trip frequency was solid and growing. Seeing a lot of growth rates. And I will say this on the ETG and the video poker side, we are seeing double-digit growth rates annually on these, but it doesn't keep up with the growth rates in the video slots.
Nick Hogan:
Gotcha.
Don Retzlaff:
So there are higher volumes of people playing ETGs and video poker, not a ton of physical real play. And if they are playing physical reels, they're playing Top Dollar and they're playing Pinball, the old Barcrest games either on the Barcrest machines or your DiamondRS machines. They're not getting a whole lot into the older Double Diamonds and Double Golds and all that type of stuff, so.
Nick Hogan:
Now Don, do you feel it's mostly just aged out? I mean, because we did see this after COVID that certain age segments, they really didn't come back and force post COVID. So is that a big part of this, you feel?
Don Retzlaff:
I do, and I think obviously the older reels, the Three Lion's stuff that has no bonus rounds, there's not a whole lot of entertainment value for a new customer. They're solid games, they're great games, but for an under 45, it's just not resonating with them. They would much rather take a shot on Ultimate Fire Link or Lock It Link or Dragon Cash or Dragon Link than they would on a physical real game.
Nick Hogan:
Gotcha.
Don Retzlaff:
There is some video poker play, but it's three and a half percent of their wallet, so not a ton of video poker play there either. And not a ton of ETG player play. It is growing, but you're still looking at south of 4% of their wallet spend. So it was just fascinating to look at and we've been studying that now for I guess almost four years.
Nick Hogan:
Yeah. I know some of the initial numbers I just couldn't believe. I was reading them and it was just so bizarre to me because I had just become so accustomed to these age groups just not being interested at all, and then all of a sudden we saw it and it was really super cool.
Don Retzlaff:
Yeah, I was so surprised that I actually reached out to the chief marketing officer of a large corporations that are you seeing the same things I'm seeing because I doubted my statistics and they confirmed that they're seeing exactly the same thing I am, that there's a lot more slot play than they expected. They really expected that social aspect of the games. I think a lot of that was COVID too. When the casino stayed open, that was pretty much the bar stayed open. So,-
Nick Hogan:
Yeah, that was the only bar in town, right, in a lot of these jurisdictions, so.
Don Retzlaff:
Yeah. So it was a place to go and gather and hang out. So they just expected that that would translate to table games and ETGs and it's more, we're seeing things like we talked about before, these group pools or people pooling their money and watching each other play or having these group pool sessions. It's been an interesting development and it's all been the last couple of years.
Nick Hogan:
Okay, cool. All right, so Don, your next one.
Don Retzlaff:
Casinos moving towards multi denominations on as many games as possible and pushing higher average bets.
Nick Hogan:
Okay.
Don Retzlaff:
And we've been lucky enough that we've had clients give us the opportunity to alter their floor mix and run some experiments. And these experiments have been very successful and ultra enlightening with one of the key takeaways being you can increase your customer's ADT by simply changing the game mix. And the way this happens is by giving the players what they want to play at the denominations they want to play, and the players will respond by increasing their average bet. And this really includes making as many games as possible multi denominations.
Nick Hogan:
Yeah.
Don Retzlaff:
Yeah. You don't have to increase their trips per month, but they will go up. You don't have to increase their trip length, but they do increase. The big key is get them to play at a higher average bet.
Nick Hogan:
Yeah.
Don Retzlaff:
And the experience,-
Nick Hogan:
Don, when we look back, I remember when Lightning Link kind of first came to market and was really doing super well. Now, was that the first game that really kind of forced that denom selection prior to gameplay? Because I recall that being as kind of a unique feature there.
Don Retzlaff:
It was and it was also the first game that got my host level dollar reel players to play a video game.
Nick Hogan:
Okay.
Don Retzlaff:
I started seeing the migration immediately after we put those games on the floor where they would come over and they would sit down and they were comfortable playing that $3 a spin that they were used to on those three coin dollar machines. And all of a sudden the trip theoretical started going up and their play started going up and it was all because of Lightning Link and the ability from them to play at a higher average bet and have some type of entertaining game.
Nick Hogan:
Yep.
Don Retzlaff:
And that's what really started it all.
Nick Hogan:
Yeah.
Don Retzlaff:
So like we talked about earlier, now that you're seeing all these games having 12 spins per minute, if you can get your customers to average an extra buck per spin, which is what we're seeing, that's an extra $200 in theoretical over three hours just based on a 10% hold.
Nick Hogan:
Right.
Don Retzlaff:
So yeah, you didn't do anything as far as marketing spend. You didn't spend more on direct mail or concerts or giveaways. All you did was pick the games that your customers will bet up on and it's producing more ADTs. And I kind of go back to same thing we did with action gaming when it came out. It's why your action gaming games make more than your five Lion Game Kings, your five Coin Game Kings. Your average bet on an action gaming video poker game is two and a half times what it is in a five Coin Game King. So you're seeing higher theoreticals and higher bets per spin and higher wins per spin, so. And we're seeing this now. We've got some of these experiments, Nick, that are in their 32nd month and we're still seeing these high average bets on these games they want to play. And it's really the biggest key to increasing revenue per customer without spending a whole lot of money.
Nick Hogan:
Yeah. Okay. Excellent. Okay. Well next up for me, Don. So what I would say has been our absolutely relentless emphasis on demand and our publications and speeches regarding premium products and cannibalization, all that stuff, I feel that they've really broken through. And what we see is that premium floor share has incremented by several points since we first began pushing the topic. And then just anecdotally, I can't tell you how many people have approached me about it saying that it's changed the game for their casinos. And the phrase that I've heard repeatedly, which I never thought that I would hear, was, "Oh, who cares about the damn lease fees?" I didn't think I would ever hear that. But some of these increments that these folks are getting are truly phenomenal. We have some, just in the time that we've had subscriptions with them that have maybe moved from three to 5% premium floor share now up over 20. It's absolutely crazy. So I've been really encouraged to see that. You, I assume see pretty much the same thing everywhere you look. Do you not Don?
Don Retzlaff:
Yeah, it's pretty uncommon now to see a floor that has less than 8% of their machines being lease games.
Nick Hogan:
Yeah.
Don Retzlaff:
And it was pretty uncommon before. Excuse me. I guess the reason is the customers are playing these games. You see those higher average bets and you're seeing these win per day on these games, especially at high occupancies. You just have to keep putting more and more on your floor. I know we got a couple clients that are north of 25%, pushing 30%, and they're setting record EBITDA levels. And I guess that's the proof in the pudding for them, is you can spend more money on fees, but your results is more EBITDA and that's the goal.
Nick Hogan:
Yeah. And it was just such an intractable perspective at some point. You'd talk to people about it, but there was just this visceral hatred of these fees and thoughts about it. And then you'd ask. I remember I was just asking around, "How do you determine the percentage of premium you have on the floor?" And I would hear things like, "Well, we keep it at 5% because the guys across the street are at seven," and stuff like this. And you just say, "Well, hold on. Is this really a way to approach it?" But these types of arguments and discussions just don't seem to be having them anymore. I think people are far more realistic about what this stuff means and how it can transform operations and I'm just really encouraged to see that.
Don Retzlaff:
Yeah, I was a little concerned, especially with seeing a lot of revenues in that flat to minus a couple percentage points this year across a lot of casinos. And what would they do? Would they decrease their lease fees because that's a cost they can control?
Nick Hogan:
Yeah.
Don Retzlaff:
I remember having a conversation with a VP of casino ops that had multiple casinos and he was talking about pulling all of his lease games off the floor. And you remember this conversation a couple of years ago and,-
Nick Hogan:
Yeah.
Don Retzlaff:
Well,-
Nick Hogan:
Yeah, I had a conversation with him later that night. It was along the lines of, "This is insane."
Don Retzlaff:
Yeah. That's,-
Nick Hogan:
We did walk him off that ledge though. That's good.
Don Retzlaff:
We did. And they have increased, thank goodness. Yeah. But yeah, I know the fees are expensive and new games are expensive. You got to spend your money wisely and you have to control the controllables and all that, but you really, really need to look at making sure that you have the best lease games on the floor, that there's no low hanging fruit out there, but you really have to have the key lease games on the floor in volume if you're going to increase your revenue per player.
Nick Hogan:
Yeah, no question. Okay, Don, your turn.
Don Retzlaff:
Well, it leads me to casinos realizing that just a small percentage of their machines make up the bulk of their revenue.
Nick Hogan:
Yeah.
Don Retzlaff:
Most casinos, 10 to 15% of the themes make up half the revenue.
Nick Hogan:
Yeah.
Don Retzlaff:
Which means that 90% of your machines make up the other 50%. And I did some research this last week on families, and if we look at the families of themes, it gets even more interesting. We have organizations and large organizations that less than 3% of the themes on the floor make up half the revenue.
Nick Hogan:
Wow.
Don Retzlaff:
Yeah. Isn't that crazy?
Nick Hogan:
That's on an enterprise wide basis?
Don Retzlaff:
Enterprise wide. Yeah.
Nick Hogan:
Wow.
Don Retzlaff:
What I did was like group themes like Dragon Link together instead of having 10 different Dragon Link themes and stuff like that. And most properties have hundreds and hundreds of themes, obviously depend on the size of your floor, but if you're looking at 1000 machine venue, most of those properties have between 400 and 600 themes on their floor.
Nick Hogan:
Yeah.
Don Retzlaff:
But you're looking at less than 10%, and organizational wide, 3% of the floor making up half your revenue. So you really want to have those games on in volume because just like we talked about before, you don't want your customers standing in line, you don't want them playing something else that they don't like to play. It's just a crazy number that so small of number of machine counts. And I think the highest I've seen so far, and I just looked at six more this last week, was 17% of the floor space gets 50% of their revenue. And that's the highest number I've seen.
Nick Hogan:
Okay. And that was the United States, or was it?
Don Retzlaff:
Yeah, that was in North America.
Nick Hogan:
Okay, okay, okay. Wow. Okay. That is a crazy statistic, but it's so acutely concentrated. I mean, I don't know how, if we can bang that gong any more loudly than we've been.
Don Retzlaff:
Yeah, I know. Just wait until my next one. It's going to be an expansion of that. It's just,-
Nick Hogan:
Okay. Okay.
Don Retzlaff:
There's so few themes out there that you really want to have in volume.
Nick Hogan:
Okay. Well, okay, so last one from my list, Don, and I'm really happy to report this one, is that operators increasingly seem to be realizing that analytics, quote, unquote is not just one big tent. Right. So as you know, so our 10-year anniversary is next month. And for me it's just fascinating. It's a great juncture to look back and see how perspectives have really evolved, all things analytics. And for years, and I'm talking as recently as 2022, basically all analytics companies were pigeonholed into this one big bucket. And over the last couple of years what we've seen is that people are realizing, wow, these companies are actually doing different things. So we're now starting to see analytics budgets that are more nuanced where they have separate line items for different solutions out there. And increasingly we're being installed beside other products that are out there. And these organizations are really starting to look at this and say, wow, the more knowledge, the more inputs we have, the better the decisions are we're making.
So that's just really good. And at the end of the day, I always return to a conversation that we have internally about the huge efficiency chasms between mainstream retail and gaming. Right. So whereas essentially all of retail grapples with inventory waste in the single digits, we're still in the high 70s in many cases when we look at inventory. So there remains a ton of room for improvement on this front. In these other industries, these solutions like ours, they've been up for 30, 40 years and it's just unthinkable to be working without them bur. So there's a lot of room for improvement, but I think it's important to celebrate these small victories, I guess. So I'm just really encouraged to see these separate budget line items that are starting to pop up.
Don Retzlaff:
And that's really happened a lot in the last year or so.
Nick Hogan:
Yeah, really.
Don Retzlaff:
But before it seems like everyone was competing for one product. They were going to get one product on their floor and everyone was looking for that one size fits all product for all our analytical tools.
Nick Hogan:
Yeah.
Don Retzlaff:
And I'll steal a line from our friends at Blue Delta Jeans. It's one size fits one.
Nick Hogan:
Yeah.
Don Retzlaff:
Right. If you're looking for something that has dispatching capabilities, that's a tool. If you're looking for something that has marketing capabilities, that's another analytical tool. If you're looking for how to spend your CapEx dollars wisely and how to spend your least dollars wisely, that's our tool. But it's been really encouraging to hear that people are putting our tool side by side with others.
Nick Hogan:
Yeah. Yeah. And it's really, I mean, the insights they really pop and it's yeah, it's just a smarter way of working. And then the other thing too is you're fighting over sometimes just kind of meaningless increments in terms of the financial commitment for some of this stuff. So it's just, get a little frustrating, but okay. Yeah. Cool. That's excellent. And okay. So Don, you're last one that you have.
Don Retzlaff:
Casinos realizing that cabinet purchases are not about the cost per box, but about the performance that these boxes deliver. It really boils down to how long do you have on a cabinet to make money before performance falls apart?
Nick Hogan:
Yeah.
Don Retzlaff:
And it's not as long as you would like or hope. Generally you have about two years on a new cabinet before it falls below house average. And that's that we've looked at over 100 casinos and it's pretty much the same everywhere. I was kind of surprised at that. I thought we'd have more time, but there are some variables. And the biggest one is how deep a library is.
Nick Hogan:
Yeah.
Don Retzlaff:
Do you have a lot of superstar titles, a lot of things that are doing over two times house average, and are you putting those on in volume? If you do that, you can get a longer shelf life out of your games. But if your library is limited, well on year three of your floor, you're going to see these boxes fall below house average, and then they're going to fall 10% a year every year after that.
Nick Hogan:
Yeah.
Don Retzlaff:
We've gone back for these cabinets that have been on the floor for a decade, and pretty much that's what happens. And it really does accelerate after they get obsoleted. So there's a couple of things you can do to prevent this. Number one, only buy cabinets that are new in their life cycle. Makes sense. Unless the manufacturer has truly committed to porting over themes from their newest cabinets down to their older cabinets. And that's a really, really, really small number of manufacturers do that and do it well. The other thing you can do is commit to having the very best themes and number, we go back to the Huff N' Even More Puff discussion, the Cosmic. Since it's released, it's doing two and a half times house average. How long before that game gets down to one and a half times house average?
Nick Hogan:
Yeah.
Don Retzlaff:
I bet you it's a decade. These games are going to be on the floor for a decade doing at least one and a half times house average. We look at something like a Buffalo Gold collection. It's still doing one and a half times house average. It's in its 10th year on the floor.
Nick Hogan:
Right. Yeah.
Don Retzlaff:
Has it slipped some? Yeah, it was doing two times house two years ago, but have casinos pulled up from the floor? They haven't. There's actually more Buffalo Gold collections on the floor today than there were two years ago. And this game is 10 years old. Which brings us to the other way of tackling aging cabinets. Take all the titles that are going off.
Nick Hogan:
Yeah.
Don Retzlaff:
Especially before they get obsoleted. We have a client with 100 ARC singles on their floor and they had a lot of them that were doing below house average. So they basically took all the games and solidated it down to five or six themes, that's it. And it increased their win per unit, and it's going to increase the life of that cabinet. And it's something I wish I would've done with my old Mark VI cabinets on the floor when they get obsoleted. So do that with your older cabinets and make those cabinets last longer. And just because they're on sale, don't jump before you do the research. Make sure those libraries have some depth to them.
Nick Hogan:
Yeah, and I can kind of share an anecdote here. Well, I'm going to do a couple of things. First of all, I'm going to shamelessly plug ReelMetrics. This has to be one of the most beloved features on the platform, is that for every single cabinet that's out there, we show you the entire library and precisely how it's performing, not only industrially, but in your operations if you have them.
So that is a hugely popular feature in ReelMetrics but, and the way that that came about was precisely through this. Yeah, it was a customer who had said to me, hey, look, I took a bath on one of these deals where they were offering buy four cabinets and get one free or something, or buy four, get two free, I think was the deal. And he put millions into this and put them out. And then within 90 days, they had just fall off the face of the earth and they went to convert them. And they were three titles in the library, none of which worked. And then that was the challenge for him. He said, "I never want this to happen again. So you figure out how to prevent that from happening again." And that's how that feature came about actually. So it was a great way to deal with it.
Don Retzlaff:
And you can see it when we're discussing 2025 cat packs purchases with all of our clients, is here's the ones you can buy a lot of cabinets of, because the library is deep. And this manufacturer has two really, really good titles on this cabinet, but that's it. So you don't want to buy 20 of those. You want to buy a bank of those. And that's what we're seeing some great results, is basically spending the money where the volume is for the depth of cabinets.
Nick Hogan:
Yeah.
Don Retzlaff:
We've helped a lot of customers out with buying stuff or their plans for 25, and there's some really good stuff out there from the non-major suppliers, but it's not worth buying 40 or 50 of those cabinets. It's buying a bank of four, a bank of six, maybe 12. But it's all because of the depth of library and those are things you really, really have to consider.
Nick Hogan:
Yeah. Gotcha. Okay, Don, so was that your last one?
Don Retzlaff:
That was my last one.
Nick Hogan:
Man, we got through a whole 10 of them. So what's the plan for Thanksgiving there? You guys doing a big,-
Don Retzlaff:
Yeah, going to moms, so.
Nick Hogan:
Okay.
Don Retzlaff:
Turkey and ham and all the fixing and getting together with the family and really being thankful for what we have.
Nick Hogan:
Okay, cool. Well, we're complete heathens over here and we don't celebrate. With a multicultural household, that's the problem. Well, it's multicultural household. So my wife is a German, I'm American, and we live in Holland. So you have three sets of holidays with which you have to contend, and we had to start chopping some, so there was no way, so Thanksgiving was on the table, but I looked and I was like, wait, so if we do Thanksgiving, no Halloween, screw Thanksgiving. Yeah. But no, we had to cut one. This one we cut. But still, I am still honoring the whole tradition of being thankful for things. So I guess that counts. Doing a podcast, I get a pass because of that. Okay. Very good, Don. Well, I think that's about it for us today then. So I wish you guys a lovely Thanksgiving there. And yeah, thanks for the very positive vibes here that we had today. Hopefully people appreciate it. A nice little respite from all the negativity that's been barraging us over the last several months. So that's kind of nice, fun thing to do.
Don Retzlaff:
Absolutely. And happy Thanksgiving to all our non-heathens out there and all of our people in North America. And enjoy the day, enjoy the football and being with your family and giving thanks.
Nick Hogan:
Indeed. So happy Thanksgiving, everybody. Okay, Don. Thanks. You take care.
Don Retzlaff:
You too, Nick. Take care.
Nick Hogan:
All right. I'll see you. Bye-bye.
`,

};

export default S03E11;
